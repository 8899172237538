import { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Form } from 'react-final-form';
import { AppleLogin, GoogleLogin } from '@belong/ui';
import classNames from 'classnames/bind';
import Button from 'components/Button/Button';
import Field from 'components/Field/Field';
import GeneralIcon, { COLORS, GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import { InputFinalFormAdapter } from 'components/Input/Input';
import {
  maskPhoneNumber,
  unmaskPhoneNumber,
  unmaskRemoveWhiteSpace,
  maskRemoveWhiteSpace,
} from 'components/Input/masks';
import config from 'config/config';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import Space from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import SetPasswordField from 'formcomponents/SetPasswordField/SetPasswordField';
import PropTypes from 'prop-types';
import { email, required, phoneValidation, composeValidators } from 'utils/validation';
import { AUTH_STRINGS } from '../../../../strings/auth.strings';
import AgreeToTermsFooter from '../AgreeToTermsFooter/AgreeToTermsFooter';
import styles from './SignUpForm.module.css';

const cx = classNames.bind(styles);

class SignUpForm extends Component {
  static title = AUTH_STRINGS['register.title'];

  static closeButton = true;

  static propTypes = {
    handleRegisterSubmit: PropTypes.func.isRequired,
    handleFacebookLoginSubmit: PropTypes.func.isRequired,
    handleGoogleLoginSuccess: PropTypes.func.isRequired,
    handleGoogleLoginFailure: PropTypes.func.isRequired,
    handleAppleCallback: PropTypes.func.isRequired,
    showLoginScreen: PropTypes.func.isRequired,
    // If true - Disable email input field
    disableEmailInput: PropTypes.bool,
    // Email address to use for signup
    emailAddress: PropTypes.string,
    // Available when coming thru homeowner calculator
    initialValues: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
  };

  static defaultProps = {
    disableEmailInput: false,
    emailAddress: '',
    initialValues: null,
    firstName: '',
    lastName: '',
    phone: '',
  };

  render() {
    const {
      showLoginScreen,
      handleRegisterSubmit,
      handleFacebookLoginSubmit,
      handleGoogleLoginSuccess,
      handleGoogleLoginFailure,
      handleAppleCallback,
      disableEmailInput,
      emailAddress,
      firstName,
      lastName,
      phone,
    } = this.props;

    let initialValues = this.props.initialValues || {};
    initialValues = { ...initialValues, firstName, lastName, phone, email: emailAddress };

    return (
      <div className={cx('register-form-wrapper')}>
        <div className={cx('register-section')}>
          <div className={cx('register-left-section')}>
            <Row>
              <Col md={12}>
                <FacebookLogin
                  appId={config.facebookAppId}
                  autoLoad={false}
                  callback={handleFacebookLoginSubmit}
                  isMobile={false}
                  render={(renderProps) => (
                    <div className={cx('sign-in-with-facebook')}>
                      <Button className={cx('facebook-button')} onClick={renderProps.onClick}>
                        <div className={cx('button-content-wrapper')}>
                          <div className={cx('icon-wrapper')}>
                            <GeneralIcon icon={GENERAL_ICONS.FACEBOOK} color={COLORS.WHITE} />
                          </div>
                          <div className={cx('text-wrapper')}>Connect with Facebook</div>
                        </div>
                      </Button>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Space.SM />
            <Row>
              <Col md={12}>
                <GoogleLogin
                  clientId={config.googleOauthClientId}
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                />
              </Col>
            </Row>
            <Space.SM />
            <Row>
              <Col md={12}>
                <AppleLogin redirectURI={config.host} callback={handleAppleCallback} />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className={cx('sign-in-section')}>
                  Already a member?
                  <ButtonBase
                    className={cx('text-button', 'sign-in-button')}
                    type="submit"
                    label="SIGN IN"
                    onClick={showLoginScreen}
                  />
                </div>
              </Col>
            </Row>
            <div className={cx('agree-to-terms-wrapper')}>{AgreeToTermsFooter}</div>
          </div>
          {/* TODO Refactor these with new components */}
          <div className={cx('register-content-desktop-divider-section')}>
            <div className={cx('divider-line')} />
            <div>Or</div>
            <div className={cx('divider-line')} />
          </div>

          <div className={cx('register-content-mobile-divider-section')}>
            <div className={cx('or')}>Or</div>
          </div>
          <div className={cx('register-form-section')}>
            <Form
              initialValues={initialValues}
              onSubmit={handleRegisterSubmit}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Field
                        name="firstName"
                        component={InputFinalFormAdapter}
                        placeholder="First name"
                        validate={required}
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name="lastName"
                        component={InputFinalFormAdapter}
                        placeholder="Last name"
                        validate={required}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Field
                        name="email"
                        component={InputFinalFormAdapter}
                        placeholder="Email"
                        validate={composeValidators(email, required)}
                        mask={maskRemoveWhiteSpace}
                        unmask={unmaskRemoveWhiteSpace}
                        disabled={disableEmailInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Field
                        name="phone"
                        component={InputFinalFormAdapter}
                        placeholder="Phone"
                        validate={composeValidators(phoneValidation, required)}
                        mask={maskPhoneNumber}
                        unmask={unmaskPhoneNumber}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <SetPasswordField />
                    </Col>
                  </Row>
                  <div className={cx('create-account-button')}>
                    <Button type="submit" label="Create Account" disabled={invalid} />
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SignUpForm;
