export const findEmployeeByType = (employees, type) =>
  employees.find((employee) => employee.employeeAssignment.assignmentType === type);

export const FORMATTED_EMPLOYEES = {
  HEAD_OF_OPERATIONS: {
    aboutMe:
      'I lead Operations at Belong, driven by a personal mission to transform the home rental experience. Originally from Argentina, I moved to the U.S. in 2019 to pursue my MBA at Stanford. In the five years since, I’ve moved five times and experienced firsthand how stressful and frustrating the process can be. That’s why I joined Belong—to make renting and managing homes seamless, and stress-free for homeowners and residents alike.',
    assignmentType: 'HeadOfOperations',
    bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/281629-a96e99620aeb980f',
    bannerImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/281629-a96e99620aeb980f',
    college: 'Stanford',
    firstName: 'Natalia',
    lastName: 'Levit',
    name: 'Natalia Levit',
    homeTown: '',
    jobTitle: 'Head Of Operations',
    memberSince: '2021-09-27T02:41:07.0561366+00:00',
    profileImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/281629-3890ed74b99206cd',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/281629-3890ed74b99206cd',
    quote: '"The way to get started is to quit talking and begin doing." - Walt Disney',
    sourceType: 'Home',
    workEmail: 'nlevit@belonghome.com',
  },
  HEAD_OF_PRICING: {
    aboutMe:
      "I manage the Move-Ins, Tours, and Pricing teams. I'm Argentinian and passionate about football (or soccer). I don’t eat meat or chicken, but I love good food. If you're looking for me, you'll likely find me at the gym early in the morning or walking on the treadmill at the Belong office. I thrive on leading teams and building cool things that improve people's lives.",
    assignmentType: 'HeadOfPricing',
    bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/282521-34ed89ba971486c8',
    bannerImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/282521-34ed89ba971486c8',
    college: 'Columbia',
    firstName: 'Veronica',
    lastName: 'Marseillan',
    name: 'Veronica Marseillan',
    homeTown: '',
    jobTitle: 'Pricing Lead',
    memberSince: '2022-04-16T19:53:37.3783279+00:00',
    profileImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/282521-752b30a5d14a0d1',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/282521-752b30a5d14a0d1',
    quote: '"The people who are crazy enough to think they can change the world are the ones who do." - Steve Jobs',
    sourceType: 'Home',
    workEmail: 'vmarseillan@belonghome.com',
  },
  HEAD_OF_EXPERIENCE: {
    aboutMe:
      'As the Head of Experience and Homecare at Belong, I’m dedicated to ensuring our members feel truly at home. My focus is on delivering exceptional care, seamless solutions, and a stress-free experience so that every home remains a place of comfort and joy.',
    assignmentType: 'HeadOfExperience',
    bannerImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/265367-fd5e91dea89ae948',
    bannerImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-employee-banner-images/265367-fd5e91dea89ae948',
    college: 'Columbia',
    firstName: 'Tamara',
    lastName: 'Epelbaum',
    homeTown: '',
    jobTitle: 'Head Of Experience',
    memberSince: '2024-04-03T21:39:41.8223133+00:00',
    name: 'Tamara Epelbaum',
    profileImage: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/265367-1c47575ae5d30855',
    profileImageUrl: 'https://s3-us-west-1.amazonaws.com/bln-prd-usr-pics/265367-1c47575ae5d30855',
    quote: '"Excellence is not a skill, it’s an attitude." – Ralph Marston',
    sourceType: 'Home',
    workEmail: 'tepelbaum@belonghome.com',
  },
};

export const betterRenewals = {
  title: 'Renewing Your Resident’s Lease',
  residentTitle: 'Let’s Renew Your Lease',
};

const bubbleTexts = {
  // These are milestones
  AgreementSigned: `Welcome, {{ customer.first_name }}! To start, let's set your home up for success by making sure we have everything we need to get your home\u00a0loved.`,
  InspectionScheduled: `Time to get your home ready to be loved, {{ customer.first_name }}! We'll put a plan in place to make sure it is ready to be shown and meets Belong's standards for quality and\u00a0care.`,
  InspectionCompleted: `Time to get your home ready to be loved, {{ customer.first_name }}! We'll put a plan in place to make sure it is ready to be shown and meets Belong's standards for quality and\u00a0care.`,
  PostInspectionProposalSent: `Your home's plan is ready for review, {{ customer.first_name }}. Once approved, we'll get right to work, and have your home loved in no\u00a0time.`,
  PostInspectionProposalApproved: `Sounds like a plan, {{ customer.first_name }}! We'll get right to work preparing your home for\u00a0love.`,
  ShowReady: `Now's the time to get your home ready for the big stage, {{ customer.first_name }}. We'll make sure it's shown in it's best\u00a0light.`,
  FullListingPublished: `It's show time, {{ customer.first_name }}! We'll chase every lead that comes your home's way, and send you weekly updates on\u00a0progress.`,
  LeaseSigned: `Alright! We found someone to love your home, {{ customer.first_name }}! We'll get to work making sure their move-in goes\u00a0smoothly.`,
  LeaseExecuted: `Alright! We found someone to love your home, {{ customer.first_name }}! We'll get to work making sure their move-in goes\u00a0smoothly.`,
  MoveInReady: `Your home is all set for your new Residents to arrive, {{ customer.first_name }}. We can't wait to welcome\u00a0them!`,
  ResidentMovedIn: `Your home is feeling the love, {{ customer.first_name }}! Your Residents are settling in, and we're making sure they're well taken care\u00a0of.`,
  ManagingAdoptedResidents: `Your Residents are all set up with their Belong account, {{ customer.first_name }}. We're excited to take care of\u00a0them.`,
  ResidentGaveMoveOutNotice: `Looks like your Residents are moving on, {{ customer.first_name }}. Time for us to work our magic and find someone new to love your\u00a0home!`,

  // This is an event.
  LeaseRenewed: `Looks like your Residents really love your home, {{ customer.first_name }}! They've renewed their lease, and we've renewed our vows to make sure your home is cared\u00a0for.`,
  LeaseStarted: `We're always here to make sure you and your Residents are well taken care, {{ customer.first_name }}. Let me know if there's anything I can do for\u00a0you!`,
};

export const getBubbleText = (unit, timeline, userFirstName) => {
  if (unit?.basicInfo?.onboardingInfo?.notInterestedInBelongServices) {
    return `Thank you for letting me know, ${userFirstName}. We'll be in touch soon to discuss next steps (and see if there's anything we can do to change your\u00a0mind).`;
  }

  // All Text changes match a milestone except for LeaseRenewed
  let lastMilestoneOrRenewal;
  if (Array.isArray(timeline)) {
    for (const event of timeline) {
      if (event.timelineEventType === 'LeaseRenewed') {
        lastMilestoneOrRenewal = bubbleTexts.LeaseRenewed;

        break;
      }

      if (event.timelineEventType === 'LeaseStarted') {
        lastMilestoneOrRenewal = bubbleTexts.LeaseStarted;

        break;
      }

      // If event has milestones, check if one of them matches a bubble text.
      const milestoneMatch = event?.milestones?.find((milestone) => {
        return bubbleTexts[milestone.name];
      });

      if (milestoneMatch) {
        lastMilestoneOrRenewal = bubbleTexts[milestoneMatch.name];

        break;
      }
    }
  }

  return lastMilestoneOrRenewal?.replace('{{ customer.first_name }}', userFirstName);
};
