import { find, isNil, uniq } from 'es-toolkit/compat';
import { getMediaTypeFromFile } from '../../../utils/file';
import { uploadDocumentToS3 } from '../common/uploadDocumentToS3';
import { fetchProDetailsById } from '../pros/actions';
import { getApiActionTypesValues } from '../redux_utils';
import { ACTIONS } from './constants';

export const fetchAccountProperties = () => ({
  types: getApiActionTypesValues('FETCH_ACCOUNT_PROPERTIES'),
  promise: ({ client }) => client.get('/homeowners/properties'),
  auth: true,
  redirectToHomeOnHide: true,
  handleError: true,
});

export const fetchAccountUnit = (unitId) => (dispatch) =>
  dispatch({
    types: getApiActionTypesValues('FETCH_ACCOUNT_UNIT'),
    promise: ({ client }) => client.get(`/homeowners/units/${unitId}`),
    auth: true,
    redirectToHomeOnHide: true,
  });

export const clearAccountUnits = () => ({
  type: ACTIONS.CLEAR_ACCOUNT_UNITS,
});

export const fetchAccountUnits = (unitIds) => (dispatch) => {
  dispatch(clearAccountUnits());

  return Promise.all(unitIds.map((unitId) => dispatch(fetchAccountUnit(unitId))));
};

export const fetchAccountUnitListings = () => ({
  types: getApiActionTypesValues('FETCH_ACCOUNT_UNIT_LISTINGS'),
  promise: ({ client }) => client.get(`/homeowners/homes-listing`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const fetchAccountUnitListingsWithPros = () => async (dispatch) => {
  const homeListings = await dispatch(fetchAccountUnitListings());
  const proPromises = [];
  const proIds = uniq(homeListings.map((listing) => listing.homeListingDetailsModel.inspectedBy?.userId)).filter(
    (proId) => !isNil(proId)
  );

  proIds.forEach((proId) => {
    proPromises.push(dispatch(fetchProDetailsById(proId)));
  });

  const pros = await Promise.all(proPromises);
  const homeListingsWithProDetails = homeListings.map((listing) => ({
    ...listing,
    homeListingDetailsModel: {
      ...listing.homeListingDetailsModel,
      inspectedBy: find(pros, { userId: listing.homeListingDetailsModel.inspectedBy?.userId }),
    },
  }));

  return homeListingsWithProDetails;
};

export const getMainenanceUploadUrl = (mediaType) => ({
  promise: ({ client }) => client.get(`/homeowners/maintenance/media/uploadurl/${mediaType}?rand=${Math.random()}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const uploadMaintenanceMedia = (file) => async (dispatch) => {
  const mediaType = getMediaTypeFromFile(file);
  const response = await dispatch(getMainenanceUploadUrl(mediaType));
  const { headers, uploadUrl, uniqueId } = response;
  await uploadDocumentToS3(dispatch, file, headers, uploadUrl);
  return {
    name: file.name,
    uniqueId,
    mediaType,
  };
};

export const updateDocuments = (name, id, mediaType) => ({
  promise: ({ client }) =>
    client.post('/homeowners/accounts/documents', {
      name,
      id,
      mediaType,
    }),
  auth: true,
  redirectToHomeOnHide: true,
});

export const getDocumentUploadUrl = (mediaType) => ({
  promise: ({ client }) => client.get(`/homeowners/accounts/documents/uploadurl/${mediaType}`),
  auth: true,
  redirectToHomeOnHide: true,
});

export const fetchHomeTimeline = (unitId) => ({
  types: getApiActionTypesValues('FETCH_HOME_TIMELINE'),
  promise: ({ client }) => client.get(`/homeowners/units/${unitId}/timeline`),
});

export const fetchPropertyTimelines = (unitIds) => async (dispatch) => {
  return Promise.all(unitIds.map((unitId) => dispatch(fetchHomeTimeline(unitId))));
};

export const fetchAccountAgreements = () => ({
  types: getApiActionTypesValues('FETCH_ACCOUNT_AGREEMENTS'),
  promise: ({ client }) => client.get('/homeowners/accounts/agreements'),
});

export const updateUnitOccupancy = (homeId, data) => ({
  promise: ({ client }) => client.put(`/homeowners/units/${homeId}/occupancy`, data),
});

export const updateBelongServicesInterest = (homeId, data) => ({
  promise: ({ client }) => client.put(`/homeowners/units/${homeId}/interest`, data),
});

export const confirmHomeAvailability = (homeId) => ({
  types: getApiActionTypesValues('CONFIRM_HOME_AVAILABILITY'),
  promise: ({ client }) => client.put(`/homeowners/units/${homeId}/confirm-availability`),
});

export const fetchUpcomingAppointments = (homeId) => ({
  types: getApiActionTypesValues('FETCH_UPCOMING_APPOINTMENTS'),
  promise: ({ client }) => client.get(`/homeowners/units/${homeId}/upcoming-appointments`),
  auth: true,
});

export const fetchWealth = () => ({
  types: getApiActionTypesValues('FETCH_WEALTH'),
  promise: ({ client }) => client.get(`/homeowners/wealth`),
  auth: true,
});

export const fetchHomeAccessInstructions = (unitId) => ({
  types: getApiActionTypesValues('FETCH_HOME_ACCESS_INSTRUCTIONS'),
  promise: ({ client }) => client.get(`/homeowners/units/${unitId}/access-instructions`),
  auth: true,
});

export const updateHomeAccessInstructions = (homeId, data) => ({
  types: getApiActionTypesValues('UPDATE_HOME_ACCESS_INSTRUCTIONS'),
  promise: ({ client }) => client.put(`/homeowners/units/${homeId}/access-instructions`, data),
  auth: true,
});
